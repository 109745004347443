import React, { memo } from 'react';
import { useSiteContext } from '../context/siteContext';

import termsAndConditions from '../docs/terms_and_conditions.pdf';

import { AppBar, Container, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
}));

const Footer = () => {
  const classes = useStyles();
  const siteContext = useSiteContext();

  return (
    <AppBar position='static' color='primary' elevation={0}>
      <Container maxWidth='lg' className={classes.container}>
        <Typography variant='body2'>{siteContext.footer.text}</Typography>
        <Typography variant='body2'>
          <Link
            href={termsAndConditions}
            target='_blank'
            variant='body2'
            color='inherit'
          >
            Terms and Conditions
          </Link>
          {' | '}
          <Link
            href={siteContext.footer.privacyPolicyUrl}
            target='_blank'
            variant='body2'
            color='inherit'
          >
            Privacy Policy
          </Link>
        </Typography>   
        <br />     
        {/* OneTrust Cookies Settings button start */}
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
        {/* OneTrust Cookies Settings button end */}
      </Container>
    </AppBar>
  );
};
export default memo(Footer);
