import React, { memo, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@material-ui/core';

import termsAndConditionsPdf from '../../docs/terms_and_conditions.pdf';

const TermsAndConditions = () => {
  const history = useHistory();

  useEffect(() => {
    window.open(termsAndConditionsPdf, '_self');
    history.replace('/');
  }, [history]);

  return <Typography variant='h3'>Terms and Conditions</Typography>;
};

export default memo(TermsAndConditions);
