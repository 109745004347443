import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
html,
body,
#root {
  height: 100%;
  margin: 0;
  background-color: #fff;
}

input {
  font-family: ${props =>
    props.siteContext.styles.muiTheme.typography.fontFamily};
}
`;
