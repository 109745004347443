import { useSiteContext } from '../context/siteContext';
const env = process.env.REACT_APP_NODE_ENV;

const apiUrls = {
  dev: 'https://pbr-api-dev.westrock.com/api',
  uat: 'https://pbr-api-uat.westrock.com/api',
  prod: 'https://pbr-api.westrock.com/api',
};

export const BASE_URL = '/';
export const API_BASE_URL = apiUrls[env];
export const API_KEY = process.env.REACT_APP_X_API_KEY;
export const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

export function Pages() {
  const siteContext = useSiteContext();
  const pages = [{ label: 'Home', href: '/' }];

  !siteContext.factsPage?.hide && pages.push({ label: 'The Facts', href: '/facts' });
  !siteContext.faqsPage?.hide && pages.push({ label: 'FAQs', href: '/faqs' })

  return pages;
}
