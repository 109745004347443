import MoonLoader from 'react-spinners/MoonLoader';
import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

const Container = styled.div`
  margin: 24px 0;
  display: flex;
  justify-content: center;
`;

export default props => {
  const theme = useTheme();
  return (
    <Container>
      <MoonLoader color={theme.palette.primary.main} />
    </Container>
  );
};
