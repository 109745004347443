import { Link } from 'react-router-dom';
import React, { memo } from 'react';
import { AppBar, Container } from '@material-ui/core';
import { useSiteContext } from '../context/siteContext';

const Header = props => {
  const siteContext = useSiteContext();
  const headerBackgroundColor = siteContext?.customTheme?.header?.backgroundColor || 'primary';

  const Logo = () => {
    return (
      <div
        style={{
          display: 'block',
          height: 36,
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <img src={siteContext.logo} alt='' {...props} height='36px' />
      </div>
    );
  };

  return (
    <AppBar position='static' color={headerBackgroundColor} elevation={0} style={{backgroundColor: headerBackgroundColor}}>
      <Container maxWidth='lg' style={{ display: 'flex' }}>
        <Link to='/'>
          <Logo />
        </Link>
      </Container>
    </AppBar>
  );
};

export default memo(Header);
