import { createContext, useContext } from 'react';

import partnerContext from '../partner';

const SiteContext = createContext(partnerContext);

export default SiteContext;

export function useSiteContext() {
  const siteContext = useContext(SiteContext);

  return siteContext;
}
