import React, { memo } from 'react';
import { AppBar, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';

const StyledAppBar = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}))(AppBar);

function Navbar() {
  return (
    <StyledAppBar position='static' elevation={0}>
      <Container maxWidth='lg'>
        <MobileNavBar />
        <DesktopNavBar />
      </Container>
    </StyledAppBar>
  );
}

export default memo(Navbar);
