import React, { memo } from 'react';
import { Typography } from '@material-ui/core';

const AppName = ({ name }) => {
  return (
    <Typography
      variant='h5'
      color='primary'
      style={{ textTransform: 'uppercase' }}
    >
      {name}
    </Typography>
  );
};

export default memo(AppName);
