import { Typography } from '@material-ui/core';
import React, { memo } from 'react';
import styled, { css } from 'styled-components';

const borderBackground = '#d9d8db';

const Container = styled.div`
  position: relative;
  font-size: ${({ size }) => size}px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  box-sizing: content-box;

  &:after {
    position: absolute;
    display: block;
    content: ' ';
    border-radius: 50%;
    border: ${({ stroke }) => stroke}px solid ${borderBackground};
    transition: all 0.2s ease-in;
    z-index: 0;
    top: 0;
    left: 0;

    ${({ size, stroke }) => {
      const innerSize = size - stroke * 2;
      const sizeEm = innerSize / size;

      return css`
        width: ${sizeEm}em;
        height: ${sizeEm}em;
      `;
    }}
  }
`;

const Pie = styled.div`
  ${({ size, stroke }) => {
    const borderSize = stroke / size;
    const width = 1 - borderSize * 2;

    return css`
      position: absolute;
      border: ${borderSize}em solid ${props => props.theme.palette.primary.main};
      width: ${width}em;
      height: ${width}em;
      border-radius: 50%;
      clip: rect(0em, 0.5em, 1em, 0em);
    `;
  }};
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Slice = styled.div`
  position: absolute;
  width: 1em;
  height: 1em;
  z-index: 1;
`;

const Bar = styled(Pie)`
  transform: rotate(${({ percentage }) => 180 - (percentage / 100) * 360}deg);
`;

const Fill = styled(Pie)`
  position: absolute;
  border-radius: 50%;

  ${({ stroke, size, percentage }) => {
    const strokeEm = stroke / size;
    const widthEm = 1 - strokeEm * 2;

    return css`
      border: ${strokeEm} solid ${props => props.theme.palette.primary.main};
      width: ${widthEm}em;
      height: ${widthEm}em;
      ${percentage > 50
        ? css`
            // fill the left half with foreground if greater than 50%
            border-color: ${props => props.theme.palette.primary.main};
          `
        : css`
            // fill the right half with background if less than 50
            transform: rotate(180deg);
            border-color: ${borderBackground};
          `}
    `;
  }}
`;

const PercentageCircle = ({ percentage, title, stroke = 43, size = 209 }) => {
  return (
    <Container size={size} stroke={stroke}>
      <TextContainer>
        <Typography
          variant='h3'
          color='primary'
          style={{ textTransform: 'uppercase' }}
        >
          {title}
        </Typography>
      </TextContainer>
      <Slice>
        <Bar percentage={percentage} size={size} stroke={stroke} />
        <Fill percentage={percentage} size={size} stroke={stroke} />
      </Slice>
    </Container>
  );
};

export default memo(PercentageCircle);
