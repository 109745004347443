import React, { memo } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSiteContext } from '../../context/siteContext';

import AppName from '../AppName';
import { Pages } from '../../util/config';

const useStyles = makeStyles(theme => ({
  sectionDesktop: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'space-between',
    },
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: 'none',
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    '&:visited, &:link': {
      color: theme.palette.primary.main,
    },

    '&:hover, &:visited:active': {
      color: theme.palette.primary.main,
      backgroundColor: fade(theme.palette.primary.main, 0.3),
    },
  },
  selected: {
    backgroundColor: fade(theme.palette.primary.main, 0.3),
    '&:visited, &:link, &:visited:active': {
      color: theme.palette.primary.main,
    },
  },
}))(props => <Tab disableRipple {...props} />);

function Desktop() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const siteContext = useSiteContext();

  return (
    <div className={classes.sectionDesktop}>
      {!siteContext?.navbar?.hide && (
        <>
          <AppName name={siteContext.appName} />
          <StyledTabs value={pathname} textColor='primary'>
            {Pages().map(page => (
              <StyledTab
                key={page.href}
                value={page.href}
                label={page.label}
                component={Link}
                to={page.href}
              />
            ))}
          </StyledTabs>
        </>
      )}
    </div>
  );
}

export default memo(Desktop);
