import React, { memo, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Drawer,
  MenuItem,
  MenuList,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSiteContext } from '../../context/siteContext';

import AppName from '../AppName';
import { Pages } from '../../util/config';

const useStyles = makeStyles(theme => ({
  sectionMobile: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  list: {
    width: 250,
    height: '100%',
    backgroundColor: theme.palette.common.white,
  },
}));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '& .MuiTypography-colorTextSecondary': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.3),
      '& .MuiTypography-colorTextSecondary': {
        color: theme.palette.primary.main,
      },
    },
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: fade(theme.palette.primary.main, 0.3),
    },
  },
}))(props => <MenuItem disableRipple {...props} />);

function MobileNavBar() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const siteContext = useSiteContext();

  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);

  const menuId = 'primary-search-account-menu';

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setMenuDrawerOpen(open);
  };

  return (
    <div className={classes.sectionMobile}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!siteContext?.navbar?.hide && (
          <IconButton
            aria-label='show more'
            aria-controls={menuId}
            aria-haspopup='true'
            onClick={toggleDrawer(true)}
            color='primary'
          >
            <MenuIcon />
          </IconButton>
        )}
        <AppName name={siteContext.appName} />
      </div>
      <Drawer anchor='left' open={menuDrawerOpen} onClose={toggleDrawer(false)}>
        <div
          className={classes.list}
          role='presentation'
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <MenuList>
            {Pages().map(page => (
              <StyledMenuItem
                key={page.href}
                component={Link}
                to={page.href}
                selected={page.href === pathname}
              >
                <Typography
                  variant='button'
                  color={page.href === pathname ? 'primary' : 'textSecondary'} // if textSecondary is changed, please change StyledMenuItem styles
                >
                  {page.label}
                </Typography>
              </StyledMenuItem>
            ))}
          </MenuList>
        </div>
      </Drawer>
    </div>
  );
}

export default memo(MobileNavBar);
