import './dominos.css';

import { Link, Typography, createMuiTheme } from '@material-ui/core';

import DominosLogo from './images/logo.png';
import FactsPageBannerImage from './images/facts_page_banner.jpg';
import FaqsPageBannerImage from './images/faqs_page_banner.jpg';
import HomePageBannerImage from './images/home_page_banner.jpg';
import HomePageSectionImage from './images/home_page_section_1@2x.png';
import PercentageCircle from '../components/PercentageCircle'; // path will be relative to src/partner folder
import PrivacyPolicyPDF from './Privacy_Policy.pdf';
import React from 'react';
import { getYear } from '../../src/util/getYear';

const headingFontFamily = `"One Dot Condensed Bold", Helvetica`;
const bodyFontFamily = `"One Dot", Helvetica`;
const buttonBorderRadius = 4;
const primaryColor = '#006491';
const secondaryColor = '#E31837';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    fontFamily: bodyFontFamily,
    fontWeightMedium: 600,
    useNextVariants: true,
    htmlFontSize: 16,
    h1: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '48px',
      lineHeight: 1.2,
      letterSpacing: '-.9px',
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '40px',
      lineHeight: 1.4,
      letterSpacing: '0px',
    },
    h3: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '32px',
      lineHeight: 1.4,
      letterSpacing: '0px',
    },
    h4: {
      fontFamily: headingFontFamily,
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: headingFontFamily,
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    h6: {
      fontFamily: headingFontFamily,
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontWeight: '600',
      fontSize: '17px',
      lineHeight: 1.4,
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: '600',
      fontSize: '15px',
      lineHeight: 1.4,
      letterSpacing: '0.1px',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '17px',
      lineHeight: 1.4,
      letterSpacing: '0.5px',
    },
    body2: {
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: 1.4,
      letterSpacing: '0.25px',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: 1.4,
      letterSpacing: '0.4px',
    },
    overline: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: 1.4,
      letterSpacing: '1.25px',
    },
    button: {
      ...defaultTheme.typography.button,
      fontFamily: headingFontFamily,
      fontSize: '16px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: buttonBorderRadius,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: buttonBorderRadius,
      },
    },
    MuiAccordion: {
      rounded: {
        '&:first-child': {
          borderTopLeftRadius: buttonBorderRadius,
          borderTopRightRadius: buttonBorderRadius,
        },
        '&:last-child': {
          borderBottomLeftRadius: buttonBorderRadius,
          borderBottomRightRadius: buttonBorderRadius,
        },
      },
    },
  },
});

export default {
  id: 'dominos',
  name: `Domino's`,
  logo: DominosLogo,
  styles: {
    muiTheme: {
      ...theme,
    },
    buttonBorderRadius: buttonBorderRadius,
  },
  appName: '',
  // meta tag values are used to generate the link preview when sharing link on social media
  metaTags: {
    title: `Domino's Recycling`,
    description: `A recent study commissioned by WestRock, our primary box supplier and one of the largest box manufacturers in the U.S., estimated that 73% of the population has access to recycling programs for empty pizza boxes. Domino's is partnering with many of the key players in the paper recycling industry to help increase the recycling of pizza boxes.`,
    url: 'https://recycling.dominos.com/',
    thumbnailImage: 'https://recycling.dominos.com/Dominos-Thumbnail-Image.png',
  },
  footer: {
    text: `© ${getYear()} Domino's IP Holder LLC. Domino’s®, Domino’s Pizza® and the modular logo are registered trademarks of Domino’s IP Holder LLC.`,
    privacyPolicyUrl: PrivacyPolicyPDF,
  },
  homePage: {
    bannerImage: HomePageBannerImage,
    headline: 'DID YOU KNOW? PIZZA BOXES ARE RECYCLABLE!',
    body: (
      <Typography variant='body1' color='textPrimary'>
        To increase the amount of pizza boxes entering the recycling stream,
        Domino’s is working with key players in the paper recycling industry to
        share the facts and divert valuable material from landfills. A 2021
        study commissioned by WestRock, our primary box supplier and one of the
        largest box manufacturers in the U.S., estimated that 70% of the
        population has access to recycling programs for empty pizza boxes.
      </Typography>
    ),
    sectionImage: HomePageSectionImage,
    recyclingGuidelinesInstructions: 'Use our lookup tool at the top of the page to find out what the pizza box recycling guidelines in your area.'
  },
  factsPage: {
    banner: {
      bannerImage: FactsPageBannerImage,
      headline: 'Unboxing the facts',
      description: `We’re making progress, but there’s still work to do. Here are some stats on pizza box recycling:`,
    },
    optionalFact: {
      headline: '72%',
      description: `In 2020, Domino's increased its percentage of recycled content from 40% to 72%`,
      source: '',
      sourceLink: '',
      icon: 'pizzabox',
    },
    headline: `We're uncovering the facts and sharing them with you!`,
    optionalLongFact: {
      headline: `Domino's Responsible Packaging`,
      sidebarContent: (
        <>
          <PercentageCircle percentage='72' title='72%' />
          <Typography variant='body2'>
            In 2020, Domino’s increased its percentage of recycled content from
            40% to 72.
          </Typography>
        </>
      ),
      content: (
        <>
          <Typography variant='body1' gutterBottom>
            Most Domino’s products leave the stores in one type of packaging –
            corrugated boxes.
          </Typography>
          <Typography variant='body1' gutterBottom>
            Domino’s currently has two corrugated box suppliers, which provide
            us with boxes made from more than 70 percent recycled content. Both
            box suppliers maintain certified fiber sourcing and chain-of-custody
            certifications using third-party audits, and our primary supplier
            uses controlled wood. Their policies do not allow the purchase of
            fiber from illegal logging or trade in illegal wood or forest
            products. Their policies also restrict the purchase from anyone in
            violation of traditional or human rights in forestry operations.
          </Typography>
        </>
      ),
    },
  },
  faqsPage: {
    banner: {
      bannerImage: FaqsPageBannerImage,
    },
  },
  emailFeedback: {
    isSupported: true,
    privacy: (
      <Typography variant='body2' color='textSecondary' gutterBottom>
        The information you share on this form will be submitted to Domino’s for
        handling and is subject to Domino’s privacy policy, which is available{' '}
        <Link
          href='https://www.dominos.com/en/#/content/privacy/'
          target='_blank'
          rel='noopener noreferrer'
        >
          here
        </Link>
        .
      </Typography>
    ),
  },
};
