import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import React, { Suspense } from 'react';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';

import { BASE_URL, GTM_ID } from './util/config';
import Facts from './screens/Facts/Loadable';
import Faqs from './screens/Faqs/Loadable';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './screens/Home/Loadable';
import Loading from './components/Loading';
import Navbar from './components/Navbar';
import Search from './screens/Search/Loadable';
import SiteDetails from './screens/SiteDetails/Loadable';
import TermsAndConditions from './screens/TermsAndConditions';

import GlobalStyles from './global-styles';
import { useSiteContext } from './context/siteContext';
import { ThemeProvider } from '@material-ui/core';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const AppContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  margin: 0;
  flex: 1;
`;

const tagManagerArgs = {
  gtmId: GTM_ID,
};

if (process.env.REACT_APP_NODE_ENV === 'prod') {
  TagManager.initialize(tagManagerArgs);
}

function App() {
  const siteContext = useSiteContext();

  return (
    <>
      <Router basename={BASE_URL}>
        <GlobalStyles siteContext={siteContext} />
        <ThemeProvider theme={siteContext.styles.muiTheme}>
          <StyledThemeProvider theme={siteContext.styles.muiTheme}>
            <AppContainer>
              <Header />
              <Navbar />
              <AppContent>
                <Suspense fallback={<Loading />}>
                  <Switch>
                    <Route path={`/faqs`} component={Faqs} />
                    <Route path={`/facts`} component={Facts} />
                    <Route path={`/lookup/:id`} component={SiteDetails} />
                    <Route path={`/lookup`} component={Search} />
                    <Route exact path={`/`} component={Home} />
                    <Route
                      path='/termsAndConditions'
                      component={TermsAndConditions}
                    />
                    <Redirect to={`/`} />
                  </Switch>
                </Suspense>
              </AppContent>
              <Footer />
            </AppContainer>
          </StyledThemeProvider>
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
